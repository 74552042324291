import React from 'react';
import { Button, FormGroup, FormControlLabel, Checkbox, Typography } from '@mui/material';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import { auth, db, firestore, storage } from '../../firebase-info';
import { navigate } from "gatsby"

const text = {
	fontFamily: 'Quicksand',
	fontSize: '24px',
	lineHeight: '24px',
	color: '#000000',
}
const textLeft = {
	fontFamily: 'Quicksand',
	fontSize: '24px',
	lineHeight: '24px',
	color: '#000000',
	marginLeft: '12px',
}
const formContainer = {
	width: '100%',
	marginTop: '32px',
}
const inputStyle = {
	width: '100%',
	backgroundColor: '#FFFFFF',
	borderRadius: '4px',
	borderWidth: '0px',
	fontFamily: 'Quicksand',
	fontSize: '24px',
	lineHeight: '24px',
	paddingLeft: '0px',
	paddingRight: '0px',
	marginTop: '32px',
}
const top32 = {
	paddingLeft: '0px',
	paddingRight: '0px',
	marginTop: '32px',
}

class RegisterForm extends React.Component {
	state = {
		email: '',
		repeatEmail: '',
		password: '',
		repeatPassword: '',
		organizationName: '',
		cif: '',
		socialReason: '',
		contactEmail: '',
		address: '',
		location: '',
		postalCode: '',
		province: '',
		orgImage: '',
		errorMessage: '',
		reference: '',
		adsCheck: false,
		legalCheck: false,
		bothCheck: false,
		adultCheck: false,
		showPassword: false,
		showRepeatedPassword: false,
		error: false
	};

	handleChange = (prop) => (event) => {
		this.setState({ ...this.state, [prop]: event.target.value });
	};

	handleCheckChange  = (event) => {
		this.setState({ ...this.state, [event.target.name]: event.target.checked });
	};

	handleBothCheck = (event) => {
		this.setState({
			...this.state,
			[event.target.name]: event.target.checked,
			legalCheck: event.target.checked,
			adsCheck: event.target.checked
		});
	}

	handleFileChange = (prop) => (event) => {
		this.setState({ ...this.state, [prop]: event.target.files });
	}

	componentDidMount() {
		ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
			if (value !== this.state.password) {
				return false;
			}
			return true;
		});
	}

	componentWillUnmount() {
		ValidatorForm.removeValidationRule('isPasswordMatch');
	}

	handleSubmit = async () => {
		try {
			const user = await auth.createUserWithEmailAndPassword(this.state.email, this.state.password)
			let newOrgRef = db.collection("organizations").doc();
			const imageUrl = 'gs://premiumandpremium.appspot.com/images/organizationsProfilePics/defaultOrgIcon.jpg';
			const imgDownloadUrl = await storage.refFromURL(imageUrl).getDownloadURL();
			const now = new Date();

			await newOrgRef.set({
				organization: this.state.organizationName,
				cif: this.state.cif,
				socialReason: this.state.socialReason,
				email: this.state.email,
				contactPerson: this.state.contactEmail,
				address: this.state.address,
				location: this.state.location,
				CP: this.state.postalCode,
				province: this.state.province,
				allowComercialCommunications: this.state.adsCheck,
				reference: this.state.reference,
				imageUrl,
				imgDownloadUrl,
				displayName: this.state.organizationName,
				hasDocuments: false,
				creationDate: firestore.Timestamp.fromDate(now)
			});
			await db.collection('users').doc(user.user.uid).set({
				email: this.state.email,
				orgRef: newOrgRef,
				role: 'org',
				creationDate: firestore.Timestamp.fromDate(now)
			});

			navigate('/dashboard');
		} catch(e) {
			const errorCode = e.code;
			const errorMessage = errorCode === 'auth/email-already-in-use' ? 'El usuario ya existe, si ha olvidado su contraseña, por favor, póngase en contacto con nosotros.' : 'Ha ocurrido un problema, por favor, inténtelo más tarde.';

			this.setState({...this.state, error: true });
			this.setState({...this.state, errorMessage });
			setTimeout(() => { this.setState({ ...this.state, error: false }) }, 3500);
		}
	}

	render() {
	const blackButton = {
		fontFamily: 'Quicksand',
		fontSize: '20px',
		lineHeight: '26px',
		fontWeight: "700",
		width: '100%',
		backgroundColor: (!this.state.legalCheck || !this.state.adultCheck) ? 'rgb(0,0,0,0.26)' :  '#000000',
		color: (!this.state.legalCheck || !this.state.adultCheck) ? 'rgb(255,255,255,0.26)' :'#FFFFFF',
		borderRadius: '50px',
		paddingLeft: '55px',
		paddingRight: '55px',
		paddingTop: '21px',
		paddingBottom: '21px',
		marginTop: '27px',
		marginBottom: '27px',
	}
	  const {
		email,
		password,
		repeatPassword,
		organizationName,
		cif,
		reference,
		error
	  } = this.state;
		return (
		  <>
			{error && <p> Algo ha ido mal, inténtalo en unos minutos</p> }
			<ValidatorForm
			  ref="form"
			  style={formContainer}
			  onSubmit={this.handleSubmit}
			  onError={errors => console.log(errors)}
			>
			  <TextValidator
				  label="Email de la organización"
				  onChange={this.handleChange('email')}
				  name="emailRegister"
				  value={email}
				  style={inputStyle}
				  validators={['required', 'isEmail']}
				  errorMessages={['Este campo es obligatorio', 'No es un email válido']}
			  />
			  <TextValidator
				  label="Nombre de la organización"
				  onChange={this.handleChange('organizationName')}
				  name="orgNameRegister"
				  style={inputStyle}
				  value={organizationName}
				  validators={['required']}
				  errorMessages={['Este campo es obligatorio']}
			  />
			  <TextValidator
				  label="CIF"
				  onChange={this.handleChange('cif')}
				  name="cifRegister"
				  style={inputStyle}
				  value={cif}
				  validators={['required']}
				  errorMessages={['Este campo es obligatorio']}
			  />
			  <TextValidator
				  label="Código de referencia (opcional)"
				  onChange={this.handleChange('reference')}
				  name="referenceRegister"
				  style={inputStyle}
				  value={reference}
			  />
			  <TextValidator
					label="Contraseña"
					onChange={this.handleChange('password')}
					name="passwordRegister"
					type="password"
					style={inputStyle}
					validators={['required', 'minStringLength:6']}
					errorMessages={['Este campo es obligatorio', 'La contraseña debe tener al menos 6 caracteres']}
					value={password}
				/>
			  <TextValidator
				  label="Repetir la contraseña"
				  onChange={this.handleChange('repeatPassword')}
				  name="repeatPasswordRegister"
				  type="password"
				  style={inputStyle}
				  validators={['isPasswordMatch', 'required']}
				  errorMessages={['Las contraseñas no coinciden', 'Este campo es obligatorio']}
				  value={repeatPassword}
			  />
			  <FormGroup column style={top32}>
				<FormControlLabel
					style={text}
					control={
						<Checkbox
						checked={this.state.adultCheck}
						onChange={this.handleCheckChange}
						name="adultCheck"
						style={{color: '#000000'}}
						/>
					}
					label="Soy mayor de edad y estoy autorizado por mi organización."
				/>
				<FormControlLabel
					style={text}
					control={
						<Checkbox
						checked={this.state.bothCheck}
						onChange={this.handleBothCheck}
						name="bothCheck"
						style={{color: '#000000'}}
						/>
					}
					label="Acepto las siguientes:"
				/>
				<FormControlLabel
					style={textLeft}
					control={
						<Checkbox
						checked={this.state.legalCheck}
						onChange={this.handleCheckChange}
						name="legalCheck"
						style={{color: '#000000'}}
						/>
					}
					label={<Typography>He leído y acepto las <a href='https://firebasestorage.googleapis.com/v0/b/premiumandpremium.appspot.com/o/documents%2FprivateDocuments%2FAviso%20Legal%20PP_revisado.pdf?alt=media&token=cd5b9132-36a2-434a-864b-663d95d7518d'>Bases Legales</a> y <a href='https://firebasestorage.googleapis.com/v0/b/premiumandpremium.appspot.com/o/documents%2FprivateDocuments%2FTerminos%20y%20condiciones_revisado.pdf?alt=media&token=8e0863cc-0823-4b68-9bf1-d22ab7a85dce'>Términos y condiciones</a></Typography>}
				/>
				<FormControlLabel
					style={textLeft}
					control={
						<Checkbox
						checked={this.state.adsCheck}
						onChange={this.handleCheckChange}
						name="adsCheck"
						style={{color: '#000000'}}
						/>
					}
					label={<Typography>Acepto recibir comunicaciones comerciales de Premium&Premium y productos y servicios de terceros de acuerdo con la <a href='https://firebasestorage.googleapis.com/v0/b/premiumandpremium.appspot.com/o/documents%2FprivateDocuments%2FPolitica%20de%20Privacidad%20PP_revisado.pdf?alt=media&token=1644c9bb-90d9-453d-98fc-52449956868c'>Política de privacidad.</a></Typography>}
				/>
			  </FormGroup>
			  <Button variant="contained" disabled={!this.state.legalCheck || !this.state.adultCheck } type="submit"  style={blackButton}> Registrarse como organización</Button>
			</ValidatorForm>
		  </>
		);
	}
}

export default RegisterForm;