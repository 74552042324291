import * as React from 'react';
import { Link } from "gatsby"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { 
	Box,
	Grid,
	Typography,
} from '@mui/material';
import PapFooter from 'components/pap-footer.js';
import LogoDowinWhite from "images/logodowinWhite.png";
import PapRegisterForm from 'components/pap-register-form.js';
import { auth } from '../../firebase-info';
import { navigate } from "gatsby"
// styles
const pageStyles = {
	fontFamily: 'Quicksand',
};
const topBar = {
	height: '56px',
	margin: '16px',
	marginTop: '0px',
}
const imageContainer = {
	height: '36.7px',
}
const headerMargin = {
	marginBottom: '20px',
	marginLeft: '16px',
	display: 'inline-block',
	verticalAlign: 'middle',
}
const verticalPadding40 = {
	paddingTop: '40px',
	paddingBottom: '40px',
}
const formContainer = {
	paddingLeft: '55px',
	paddingRight: '55px',
	backgroundColor: '#FFFFFF',
	borderRadius: '12px',
}
const heavyBlue = {
	backgroundColor: '#2C90CB',
}


// markup
const RegisterPage = () => {
	const theme = createTheme(
		{
			components: {
				MuiInputBase: {
					styleOverrides: {
						root: {
							fontFamily: 'Quicksand !important',
							color: '#000000',
						}
					}
				},
				MuiToolbar: {
					styleOverrides: {
						root: {
							fontFamily: 'Quicksand !important',
							color: '#FFFFFF',
						},
					},
				},
				MuiInputLabel: {
					styleOverrides: {
						root: {
							fontFamily: 'Quicksand !important',
						},
					},
				},
			},
		},
	);

	async function checkLogin() {
		if (auth.currentUser !== null) {
		   navigate("/dashboard");
		   return;
		}
	};
  
	React.useEffect(() => {
	  checkLogin();
	}, []);

	return (
		<main>
			<Box style={pageStyles}>
				<Box style={heavyBlue}>
					<Grid container spacing={2} style={topBar}>
						<Grid item xs={12}>
							<Link to="/">
								<img
									alt="dowin Logo"
									style={imageContainer}
									src={LogoDowinWhite}
								/>
							</Link>
							<Box style={headerMargin}>
								<Typography fontFamily={"Quicksand"} color={"#FFFFFF"} display={"inline"}> Organizaciones y solicitantes </Typography>
							</Box>
						</Grid>
					</Grid>
					<Grid style={verticalPadding40} justifyContent={"center"} container spacing={2}>
						<Grid item style={formContainer} xs={6}>
							<ThemeProvider theme={theme}>
								<PapRegisterForm/>
							</ThemeProvider>
						</Grid>
					</Grid>
				</Box>
			</Box>
			<PapFooter/>
		</main>
	);
};

export default RegisterPage;
